import { render, staticRenderFns } from "./LeftTitle.vue?vue&type=template&id=72a6cf63&"
var script = {}
import style0 from "./LeftTitle.vue?vue&type=style&index=0&id=72a6cf63&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports